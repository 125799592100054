.p-0 {
    padding: 0px !important;
}

.p-1 {
    padding: 1px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-3 {
    padding: 3px !important;
}

.p-4 {
    padding: 4px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-6 {
    padding: 6px !important;
}

.p-7 {
    padding: 7px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-9 {
    padding: 9px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-11 {
    padding: 11px !important;
}

.p-12 {
    padding: 12px !important;
}

.p-13 {
    padding: 13px !important;
}

.p-14 {
    padding: 14px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-17 {
    padding: 17px !important;
}

.p-18 {
    padding: 18px !important;
}

.p-19 {
    padding: 19px !important;
}

.p-20 {
    padding: 20px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 1px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-3 {
    padding-top: 3px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-6 {
    padding-top: 6px !important;
}

.pt-7 {
    padding-top: 7px !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-9 {
    padding-top: 9px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-11 {
    padding-top: 11px !important;
}

.pt-12 {
    padding-top: 12px !important;
}

.pt-13 {
    padding-top: 13px !important;
}

.pt-14 {
    padding-top: 14px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-16 {
    padding-top: 16px !important;
}

.pt-17 {
    padding-top: 17px !important;
}

.pt-18 {
    padding-top: 18px !important;
}

.pt-19 {
    padding-top: 19px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-3 {
    padding-bottom: 3px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-6 {
    padding-bottom: 6px !important;
}

.pb-7 {
    padding-bottom: 7px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-9 {
    padding-bottom: 9px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-11 {
    padding-bottom: 11px !important;
}

.pb-12 {
    padding-bottom: 12px !important;
}

.pb-13 {
    padding-bottom: 13px !important;
}

.pb-14 {
    padding-bottom: 14px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.pb-17 {
    padding-bottom: 17px !important;
}

.pb-18 {
    padding-bottom: 18px !important;
}

.pb-19 {
    padding-bottom: 19px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 3px !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-6 {
    padding-left: 6px !important;
}

.pl-7 {
    padding-left: 7px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-9 {
    padding-left: 9px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-11 {
    padding-left: 11px !important;
}

.pl-12 {
    padding-left: 12px !important;
}

.pl-13 {
    padding-left: 13px !important;
}

.pl-14 {
    padding-left: 14px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pl-17 {
    padding-left: 17px !important;
}

.pl-18 {
    padding-left: 18px !important;
}

.pl-19 {
    padding-left: 19px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 3px !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-6 {
    padding-right: 6px !important;
}

.pr-7 {
    padding-right: 7px !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pr-9 {
    padding-right: 9px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-11 {
    padding-right: 11px !important;
}

.pr-12 {
    padding-right: 12px !important;
}

.pr-13 {
    padding-right: 13px !important;
}

.pr-14 {
    padding-right: 14px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-16 {
    padding-right: 16px !important;
}

.pr-17 {
    padding-right: 17px !important;
}

.pr-18 {
    padding-right: 18px !important;
}

.pr-19 {
    padding-right: 19px !important;
}

.pr-20 {
    padding-right: 20px !important;
}