/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./font-stylesheet.css";
@import "./margin-stylesheet.css";
@import "./padding-stylesheet.css";
.custom-button-primary ion-button {
  height: 48px !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 16px !important;
  background: #080A52 !important;
  --background: #080A52 !important;
  color: #fff !important;
  border-radius: 12px !important;
  --border-radius: 12px !important;
  border: 1px solid #080A52 !important;
  text-transform: none !important;
}
.custom-button-primary ion-button:hover:enabled {
  background: #060845 !important;
  color: #FFFFFF !important;
  border: 1px solid #060845 !important;
}

.custom-button-secondary ion-button {
  height: 48px !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 16px !important;
  background: #fff !important;
  --background: #fff !important;
  color: #080A52 !important;
  border-radius: 12px !important;
  --border-radius: 12px !important;
  border: 1px solid #fff !important;
  text-transform: none !important;
}
.custom-button-secondary ion-button:hover:enabled {
  background: #E6E7EE !important;
  color: #060845 !important;
  border: 1px solid #E6E7EE !important;
}

.custom-button-ternary ion-button {
  height: 48px !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 16px !important;
  background: #E6E7EE !important;
  --background: #E6E7EE !important;
  color: #080A52 !important;
  border-radius: 12px !important;
  --border-radius: 12px !important;
  border: 1px solid #E6E7EE !important;
  text-transform: none !important;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04) !important;
}
.custom-button-ternary ion-button:hover:enabled {
  background: #E6E7EE !important;
  color: #060845 !important;
  border: 1px solid #E6E7EE !important;
}

button[disabled] {
  background: #EBEBF1 !important;
  color: #B2B3CB !important;
  border: 1px solid #EBEBF1 !important;
  opacity: 0.6;
  cursor: not-allowed;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04);
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #080A52;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #E6E7EE;
  --ion-color-primary-tint: #eee;
  /** secondary **/
  --ion-color-secondary: #1A1A1A;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-ternary: #636363;
  --ion-color-ternary-rgb: 82, 96, 255;
  --ion-color-ternary-contrast: #ffffff;
  --ion-color-ternary-contrast-rgb: 255, 255, 255;
  --ion-color-ternary-shade: #4854e0;
  --ion-color-ternary-tint: #6370ff;
  /** success **/
  --ion-color-success: #00AB7A;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #E6F7F2;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #F28500;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** warning **/
  --ion-color-warning-1: #E67100;
  --ion-color-warning-1-rgb: 255, 196, 9;
  --ion-color-warning-1-contrast: #000000;
  --ion-color-warning-1-contrast-rgb: 0, 0, 0;
  --ion-color-warning-1-shade: #FFF2E6;
  --ion-color-warning-1-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-backdrop-color: rgba(26, 26, 26, 0.48);
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

:host {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body {
    /** primary **/
    --ion-color-primary: #080A52;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #E6E7EE;
    --ion-color-primary-tint: #eee;
    /** secondary **/
    --ion-color-secondary: #1A1A1A;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
    /** tertiary **/
    --ion-color-ternary: #636363;
    --ion-color-ternary-rgb: 82, 96, 255;
    --ion-color-ternary-contrast: #ffffff;
    --ion-color-ternary-contrast-rgb: 255, 255, 255;
    --ion-color-ternary-shade: #4854e0;
    --ion-color-ternary-tint: #6370ff;
    /** success **/
    --ion-color-success: #00AB7A;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #E6F7F2;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #F28500;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** warning **/
    --ion-color-warning-1: #E67100;
    --ion-color-warning-1-rgb: 255, 196, 9;
    --ion-color-warning-1-contrast: #000000;
    --ion-color-warning-1-contrast-rgb: 0, 0, 0;
    --ion-color-warning-1-shade: #FFF2E6;
    --ion-color-warning-1-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-backdrop-color: rgba(26, 26, 26, 0.48);
    --padding-start: 0px !important;
    --padding-end: 0px !important;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
  }
}
:host {
  font-family: "Graphik" !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

html {
  font-family: "Graphik" !important;
}

body {
  font-family: "Graphik" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
label,
ion-col,
ion-row {
  font-family: "Graphik" !important;
}

ion-content,
ion-header,
ion-footer,
app-onboarding-header,
ion-toolbar,
ion-tab-bar,
ion-segment {
  font-family: "Graphik" !important;
  --background: #fff !important;
  background: #fff !important;
}

ion-tab-bar {
  --color-selected: none !important;
}

ion-card {
  --background: #fff !important;
  background: #fff !important;
}

ion-tab-bar {
  border-top: 1px solid #E8E8E8;
}

.primary {
  color: var(--ion-color-primary);
}

.secondary {
  color: var(--ion-color-secondary);
}

.ternary {
  color: var(--ion-color-ternary);
}

.warning {
  color: var(--ion-color-warning);
}

.danger {
  color: var(--ion-color-danger);
}

.backgroud-color {
  background: #E8E8E8 !important;
  --background: #E8E8E8 !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

@media screen and (max-width: 320px) {
  .main-body-heading-2xl {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-xl {
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-lg {
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-md {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-sm {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}
@media screen and (min-width: 321px) and (max-width: 768px) {
  .main-body-heading-2xl {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-xl {
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-lg {
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-md {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-sm {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .main-body-heading-2xl {
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-xl {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-lg {
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-md {
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-sm {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .main-body-heading-2xl {
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-xl {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-lg {
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-md {
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-sm {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}
@media screen and (min-width: 1441px) {
  .main-body-heading-2xl {
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-xl {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-lg {
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-md {
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .main-body-heading-sm {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}
.sub-heading-xl {
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
}

.sub-heading-xl-md {
  font-style: normal;
  font-size: 19.2px;
  line-height: 29px;
}

.sub-heading-lg {
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
}

.sub-heading-md {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.sub-heading-sm {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.sub-heading-xs {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.sub-heading-xxs {
  font-size: 8px;
  font-style: normal;
  line-height: 10px;
  /* 125% */
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-contents {
  display: contents;
}

.c-pointer {
  cursor: pointer;
}

.header-md::after {
  background-image: none !important;
}

.position-fixed {
  position: fixed;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-decoration-underline {
  text-decoration: underline;
}

.inner-scroll {
  height: calc(100% - 175px) !important;
}

ion-footer {
  bottom: 0px;
  min-height: 64px;
  padding: 16px 16px 16px 16px;
  background: #fff;
  border: 1px solid #E8E8E8;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

ion-chip {
  border-radius: 32px;
  background: #E8E8E8;
  padding: 4px 12px;
}

.custom-loader .loading-wrapper {
  background: transparent !important;
  box-shadow: none !important;
}

.refresher-refreshing-icon,
.refresher-pulling-icon {
  background: transparent !important;
  padding: 0px !important;
  border: none !important;
  box-shadow: none !important;
}

.search-wrap img {
  height: 20px !important;
}

.data-not-found-wrap {
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  margin-inline: 0px;
  margin-top: 0px;
  margin: 0 16px 0 16px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.data-not-found-wrap .center-card-wrap {
  max-width: 50%;
  width: 50%;
  position: absolute;
  top: 60%;
  transform: translate(50%, -50%);
  padding: 10px;
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 769px) {
  .center-card-wrap {
    max-width: 100% !important;
    width: 100% !important;
    transform: translate(0%, -50%) !important;
    top: 50% !important;
  }

  .data-not-found-wrap {
    width: 92% !important;
  }
}
.bottom-border {
  border-bottom: 1px solid #E8E8E8;
}

ion-segment-button {
  border-bottom: none;
}
ion-segment-button .segment-button-checked {
  border-bottom: 2px solid #080A52;
}
ion-segment-button ion-label {
  text-transform: none;
  letter-spacing: normal;
  color: var(--ion-color-ternary);
}

.completed-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-success-shade);
  border-radius: 32px;
}
.completed-bedge label {
  color: var(--ion-color-success);
}

.pending-bedge {
  padding: 2px 8px;
  height: 22px;
  --bg-opacity: 1;
  background: rgba(255, 193, 202, var(--bg-opacity));
  border-radius: 32px;
}
.pending-bedge label {
  --text-opacity: 1;
  color: rgba(204, 43, 67, var(--text-opacity));
}

.approved-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-primary-shade);
  border-radius: 32px;
}
.approved-bedge label {
  color: var(--ion-color-primary);
}

.pending-inreview-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-warning-1-shade);
  border-radius: 32px;
}
.pending-inreview-bedge label {
  color: var(--ion-color-warning-1);
}

.standBy-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-primary-shade);
  border-radius: 32px;
}
.standBy-bedge label {
  color: var(--ion-color-primary);
}

.rejected-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-primary-shade);
  border-radius: 32px;
}
.rejected-bedge label {
  color: var(--ion-color-primary);
}

.unwilling-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-primary-shade);
  border-radius: 32px;
}
.unwilling-bedge label {
  color: var(--ion-color-primary);
}

.apply-later-bedge {
  padding: 2px 8px;
  height: 22px;
  background: var(--ion-color-primary-shade);
  border-radius: 32px;
}
.apply-later-bedge label {
  color: var(--ion-color-primary);
}

.my-custom-interface .select-interface-option {
  --button-color: #971e49;
  --button-color-hover: #79193b;
}

.logout-actionsheet .action-sheet-group {
  padding: 0px 0px 0px 0px !important;
}

.action-sheet-group {
  margin: 0px 16px 16px 16px;
  padding: 8px 0px 8px 0px;
  background: #FFFFFF !important;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 8px -2px rgba(26, 26, 26, 0.1), 0px 2px 4px -2px rgba(26, 26, 26, 0.06);
  border-radius: 12px;
  --background: #fff;
}
.action-sheet-group .action-sheet-title {
  color: var(--ion-color-secondary);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.action-sheet-group .action-sheet-button {
  padding: 12px 20px 12px 20px;
}
.action-sheet-group .action-sheet-button-inner {
  color: var(--ion-color-secondary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.action-sheet-group .action-sheet-selected .action-sheet-button-inner {
  color: var(--ion-color-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.action-sheet-group .action-sheet-selected .action-sheet-button-inner::after {
  content: "";
  display: block;
  width: 5px;
  height: 13px;
  border: 1.5px solid var(--ion-color-primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-left: auto;
}

.action-sheet-group-cancel {
  margin: 0px 16px 0px 16px;
  padding: unset;
  border: 1px solid #E6E7EE;
  border-radius: 12px;
  --background: #fff;
}
.action-sheet-group-cancel .action-sheet-cancel .action-sheet-button-inner {
  justify-content: center;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--ion-color-secondary);
}

.delete-actionsheet .action-sheet-group .delete-btn .action-sheet-button-inner {
  color: #eb445a !important;
}
.delete-actionsheet .action-sheet-title,
.delete-actionsheet .action-sheet-button-inner,
.delete-actionsheet .delete-btn {
  text-align: center !important;
  justify-content: center !important;
}

ion-select::part(icon) {
  content: url("./assets/icon/arrow-down.png");
  margin-left: 8px;
}

ion-backdrop {
  backdrop-filter: blur(8px);
}

.select-text {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

#custom-select .select-text {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

.button-native {
  padding: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

#custom-tab ion-tab-button a {
  padding: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.counter-bedge {
  background: #E6E7EE;
  border-radius: 32px;
}

ion-tab-button::part(native) {
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

ng-otp-input {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.ng-otp-input-wrapper {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
}
.ng-otp-input-wrapper .otp-input {
  background: #fff !important;
  padding: 10px !important;
  color: #1a1a1a !important;
  text-align: center !important;
  margin-right: 12px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  width: 44.67px !important;
  height: 44px !important;
  background: #FFFFFF !important;
  border: 1px solid #E8E8E8 !important;
  border-radius: 12px !important;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04) !important;
}
.ng-otp-input-wrapper input:valid {
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  color: #1A1A1A;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04), 0px 0px 0px 4px #F5F5F8;
}
.ng-otp-input-wrapper input:focus {
  outline: none;
  background: #FFFFFF;
  border: 1px solid #B2B3CB !important;
  color: #636363;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04), 0px 0px 0px 4px #F5F5F8;
}
@media screen and (max-width: 320px) {
  .ng-otp-input-wrapper .otp-input {
    width: 40px !important;
    height: 40px !important;
    margin-right: 8px !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 424px) {
  .ng-otp-input-wrapper .otp-input {
    width: 46px !important;
    height: 46px !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 768px) {
  .ng-otp-input-wrapper .otp-input {
    width: 80px !important;
    height: 50px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .ng-otp-input-wrapper .otp-input {
    width: 110px !important;
    height: 60px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .ng-otp-input-wrapper .otp-input {
    width: 150px !important;
    height: 60px !important;
  }
}
@media screen and (min-width: 1441px) {
  .ng-otp-input-wrapper .otp-input {
    width: 200px !important;
    height: 60px !important;
  }
}

@media (prefers-color-scheme: dark) {
  .alert-title {
    color: #fff !important;
  }

  .cancel-btn {
    color: #fff !important;
  }

  .exit-btn {
    color: #fff !important;
  }
}
@media (prefers-color-scheme: light) {
  .alert-title {
    color: var(--ion-color-primary) !important;
  }

  .cancel-btn {
    color: var(--ion-color-primary) !important;
  }

  .exit-btn {
    color: var(--ion-color-primary) !important;
  }
}
#exit-alert,
.exit-alert {
  z-index: 999999999999 !important;
}

.text-white {
  color: #fff;
}