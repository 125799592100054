/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// IMPORT FONT-FAMILY
@import "./font-stylesheet.css";

// IMPORT SPACING FILES
@import "./margin-stylesheet.css";
@import "./padding-stylesheet.css";

// IMPORT ION-BUTTON CSS
@import "./ion-button-stylesheet.scss";

// Global color add
@import "./theme/variables.scss";

:host {
    font-family: "Graphik" !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
}

html {
    font-family: "Graphik" !important;
}

body {
    font-family: "Graphik" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
label,
ion-col,
ion-row {
    font-family: "Graphik" !important;
}

ion-content,
ion-header,
ion-footer,
app-onboarding-header,
ion-toolbar,
ion-tab-bar,
ion-segment {
    font-family: "Graphik" !important;
    --background: #fff !important;
    background: #fff !important;
}

ion-tab-bar {
    --color-selected: none !important;
}

ion-card {
    --background: #fff !important;
    background: #fff !important;
}

ion-tab-bar {
    border-top: 1px solid #E8E8E8;
}

// COLOR CODE
.primary {
    color: var(--ion-color-primary);
}

.secondary {
    color: var(--ion-color-secondary);
}

.ternary {
    color: var(--ion-color-ternary);
}

.warning {
    color: var(--ion-color-warning);
}

.danger {
    color: var(--ion-color-danger);
}

.backgroud-color {
    background: #E8E8E8 !important;
    --background: #E8E8E8 !important;
}

// FONT WEIGHT
.fw-400 {
    font-weight: 400; // Regular Font
}

.fw-500 {
    font-weight: 500; // Medium Font
}

.fw-600 {
    font-weight: 600; // Semi-Bold Font
}

.fw-700 {
    font-weight: 700; // Bold font
}


// HEADER STYLES
@media screen and (max-width: 320px) {
    .main-body-heading-2xl {
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-xl {
        font-style: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-lg {
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-md {
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-sm {
        font-style: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
    }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
    .main-body-heading-2xl {
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-xl {
        font-style: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-lg {
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-md {
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-sm {
        font-style: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .main-body-heading-2xl {
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-xl {
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-lg {
        font-style: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-md {
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-sm {
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .main-body-heading-2xl {
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-xl {
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-lg {
        font-style: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-md {
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-sm {
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
}

@media screen and (min-width: 1441px) {
    .main-body-heading-2xl {
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-xl {
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-lg {
        font-style: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-md {
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .main-body-heading-sm {
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
}


// SUB-HEADER STYLES
.sub-heading-xl {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    // letter-spacing: -0.02em;
}

.sub-heading-xl-md {
    font-style: normal;
    font-size: 19.2px;
    line-height: 29px;
    // letter-spacing: -0.02em;
}

.sub-heading-lg {
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    // letter-spacing: -0.02em;
}

.sub-heading-md {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    // letter-spacing: -0.02em;
}

.sub-heading-sm {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    // letter-spacing: -0.02em;
}

.sub-heading-xs {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    // letter-spacing: -0.02em;
}

.sub-heading-xxs {
    font-size: 8px;
    font-style: normal;
    line-height: 10px;
    /* 125% */
}

// GENERAL STYLES
.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-space-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex;
}

.d-grid {
    display: grid;
}

.d-contents {
    display: contents;
}

.c-pointer {
    cursor: pointer;
}

.header-md::after {
    background-image: none !important;
}

.position-fixed {
    position: fixed;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.text-decoration-underline {
    text-decoration: underline;
}

.inner-scroll {
    height: calc(100% - 175px) !important;
}

ion-footer {
    bottom: 0px;
    min-height: 64px;
    padding: 16px 16px 16px 16px;
    background: #fff;
    border: 1px solid #E8E8E8;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

ion-chip {
    border-radius: 32px;
    background: #E8E8E8;
    padding: 4px 12px;
}

.custom-loader {
    .loading-wrapper {
        background: transparent !important;
        box-shadow: none !important;
    }
}

.refresher-refreshing-icon,
.refresher-pulling-icon {
    background: transparent !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}

.search-wrap {
    img {
        height: 20px !important;
    }
}

.data-not-found-wrap {
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    margin-inline: 0px;
    margin-top: 0px;
    margin: 0 16px 0 16px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;

    .center-card-wrap {
        max-width: 50%;
        width: 50%;
        position: absolute;
        top: 60%;
        transform: translate(50%, -50%);
        padding: 10px;
        text-align: center;
        background: transparent;
        border: none;
        box-shadow: none;
    }

}

@media screen and (max-width: 769px) {
    .center-card-wrap {
        max-width: 100% !important;
        width: 100% !important;
        transform: translate(0%, -50%) !important;
        top: 50% !important;
    }

    .data-not-found-wrap {
        width: 92% !important;
    }
}

.bottom-border {
    border-bottom: 1px solid #E8E8E8;
}

// Segment css
ion-segment-button {
    .segment-button-checked {
        border-bottom: 2px solid #080A52;
    }

    border-bottom: none;

    ion-label {
        text-transform: none;
        letter-spacing: normal;
        color: var(--ion-color-ternary);
    }
}


// Bedge css
.completed-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-success-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-success);
    }
}

.pending-bedge {
    padding: 2px 8px;
    height: 22px;
    --bg-opacity: 1;
    background: rgba(255, 193, 202, var(--bg-opacity));
    border-radius: 32px;

    label {
        --text-opacity: 1;
        color: rgba(204, 43, 67, var(--text-opacity));
    }
}

.approved-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-primary-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-primary);
    }
}

.pending-inreview-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-warning-1-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-warning-1);
    }
}

.standBy-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-primary-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-primary);
    }
}

.rejected-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-primary-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-primary);
    }
}

.unwilling-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-primary-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-primary);
    }
}

.apply-later-bedge {
    padding: 2px 8px;
    height: 22px;
    background: var(--ion-color-primary-shade);
    border-radius: 32px;

    label {
        color: var(--ion-color-primary);
    }
}

// Custom Select
.my-custom-interface .select-interface-option {
    --button-color: #971e49;
    --button-color-hover: #79193b;
}

.logout-actionsheet {
    .action-sheet-group {
        padding: 0px 0px 0px 0px !important;
    }
}

.action-sheet-group {
    margin: 0px 16px 16px 16px;
    padding: 8px 0px 8px 0px;
    background: #FFFFFF !important;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 4px 8px -2px rgba(26, 26, 26, 0.1), 0px 2px 4px -2px rgba(26, 26, 26, 0.06);
    border-radius: 12px;
    --background: #fff;

    .action-sheet-title {
        color: var(--ion-color-secondary);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .action-sheet-button {
        padding: 12px 20px 12px 20px;
    }

    .action-sheet-button-inner {
        color: var(--ion-color-secondary);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .action-sheet-selected {
        .action-sheet-button-inner {
            color: var(--ion-color-primary);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .action-sheet-button-inner::after {
            content: "";
            display: block;
            width: 5px;
            height: 13px;
            border: 1.5px solid var(--ion-color-primary);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            margin-left: auto;
        }
    }
}

.action-sheet-group-cancel {
    margin: 0px 16px 0px 16px;
    padding: unset;
    border: 1px solid #E6E7EE;
    border-radius: 12px;
    --background: #fff;

    .action-sheet-cancel {
        .action-sheet-button-inner {
            justify-content: center;
            background: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--ion-color-secondary);
        }
    }
}

.delete-actionsheet {
    .action-sheet-group {
        .delete-btn {
            .action-sheet-button-inner {
                color: #eb445a !important;
            }
        }
    }

    .action-sheet-title,
    .action-sheet-button-inner,
    .delete-btn {
        text-align: center !important;
        justify-content: center !important;
    }
}

ion-select::part(icon) {
    content: url('./assets/icon/arrow-down.png');
    margin-left: 8px;
}

ion-backdrop {
    backdrop-filter: blur(8px);
}

.select-text {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--ion-color-primary) !important;
    --color: var(--ion-color-primary) !important;
}


#custom-select {
    .select-text {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: var(--ion-color-primary) !important;
        --color: var(--ion-color-primary) !important;
    }
}

.button-native {
    padding: 0px !important;
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
}

#custom-tab {
    ion-tab-button {
        a {
            padding: 0px !important;
            padding-inline-start: 0px !important;
            padding-inline-end: 0px !important;
            --padding-start: 0px !important;
            --padding-end: 0px !important;
        }
    }
}

.counter-bedge {
    background: #E6E7EE;
    border-radius: 32px;
}

ion-tab-button::part(native) {
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
}

// Otp Input CSS
ng-otp-input {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
}

.ng-otp-input-wrapper {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;

    .otp-input {
        background: #fff !important;
        padding: 10px !important;
        color: #1a1a1a !important;
        text-align: center !important;
        margin-right: 12px !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        width: 44.67px !important;
        height: 44px !important;
        background: #FFFFFF !important;
        border: 1px solid #E8E8E8 !important;
        border-radius: 12px !important;
        box-shadow: 0px 1px 2px rgb(26 26 26 / 4%) !important;
    }

    input:valid {
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        color: #1A1A1A;
        box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04), 0px 0px 0px 4px #F5F5F8;
    }

    input:focus {
        outline: none;
        background: #FFFFFF;
        border: 1px solid #B2B3CB !important;
        color: #636363;
        box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.04), 0px 0px 0px 4px #F5F5F8;
    }

    @media screen and (max-width: 320px) {
        .otp-input {
            width: 40px !important;
            height: 40px !important;
            margin-right: 8px !important;
        }
    }

    @media screen and (min-width: 321px) and (max-width: 424px) {
        .otp-input {
            width: 46px !important;
            height: 46px !important;
        }
    }

    @media screen and (min-width: 425px) and (max-width: 768px) {
        .otp-input {
            width: 80px !important;
            height: 50px !important;
        }
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        .otp-input {
            width: 110px !important;
            height: 60px !important;
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        .otp-input {
            width: 150px !important;
            height: 60px !important;
        }
    }

    @media screen and (min-width: 1441px) {
        .otp-input {
            width: 200px !important;
            height: 60px !important;
        }
    }
}

@media (prefers-color-scheme: dark) {
    .alert-title {
        color: #fff !important;
    }

    .cancel-btn {
        color: #fff !important;
    }

    .exit-btn {
        color: #fff !important;
    }
}

@media (prefers-color-scheme: light) {
    .alert-title {
        color: var(--ion-color-primary) !important;
    }

    .cancel-btn {
        color: var(--ion-color-primary) !important;
    }

    .exit-btn {
        color: var(--ion-color-primary) !important;
    }
}

#exit-alert,
.exit-alert {
    z-index: 999999999999 !important;
}

.text-white {
    color: #fff;
}