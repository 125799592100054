.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 1px !important;
}

.m-2 {
    margin: 2px !important;
}

.m-3 {
    margin: 3px !important;
}

.m-4 {
    margin: 4px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-6 {
    margin: 6px !important;
}

.m-7 {
    margin: 7px !important;
}

.m-8 {
    margin: 8px !important;
}

.m-9 {
    margin: 9px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-11 {
    margin: 11px !important;
}

.m-12 {
    margin: 12px !important;
}

.m-13 {
    margin: 13px !important;
}

.m-14 {
    margin: 14px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-16 {
    margin: 16px !important;
}

.m-17 {
    margin: 17px !important;
}

.m-18 {
    margin: 18px !important;
}

.m-19 {
    margin: 19px !important;
}

.m-20 {
    margin: 20px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-7 {
    margin-top: 7px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-11 {
    margin-top: 11px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-17 {
    margin-top: 17px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-19 {
    margin-top: 19px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1 {
    margin-bottom: 1px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-9 {
    margin-bottom: 9px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-11 {
    margin-bottom: 11px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-13 {
    margin-bottom: 13px !important;
}

.mb-14 {
    margin-bottom: 14px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-17 {
    margin-bottom: 17px !important;
}

.mb-18 {
    margin-bottom: 18px !important;
}

.mb-19 {
    margin-bottom: 19px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-22 {
    margin-bottom: 22px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 1px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.ml-7 {
    margin-left: 7px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-9 {
    margin-left: 9px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-11 {
    margin-left: 11px !important;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-13 {
    margin-left: 13px !important;
}

.ml-14 {
    margin-left: 14px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-17 {
    margin-left: 17px !important;
}

.ml-18 {
    margin-left: 18px !important;
}

.ml-19 {
    margin-left: 19px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-2 {
    margin-right: 2px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-9 {
    margin-right: 9px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-11 {
    margin-right: 11px !important;
}

.mr-12 {
    margin-right: 12px !important;
}

.mr-13 {
    margin-right: 13px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-17 {
    margin-right: 17px !important;
}

.mr-18 {
    margin-right: 18px !important;
}

.mr-19 {
    margin-right: 19px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-24 {
    margin-right: 24px !important;
}